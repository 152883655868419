import '../css/app.scss';


import { gsap } from "gsap";

/* The following plugin is a Club GSAP perk */
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import Accordion from './components/accordion';
import Hamburger from './components/hamburger';
import ShieldForm from './components/shieldForm';


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

ScrollSmoother.create({
  smooth: 0.5,
  effects: true,
});

$(() => {
	// console.log('Hello World');
  

  const $accordions = $('[data-accordion="el"]');

  $accordions.each(function(i, element) {
    new Accordion($(element));
  })

  const $hamburger = $('[data-hamburger="el"]');
  const $backrop = $('[data-backdrop="el"]');
  const $shieldForm = $('[data-shield-form="el"]');

  if($shieldForm.length) {
    new ShieldForm($shieldForm);
  }

  new Hamburger($hamburger, $backrop);

});



