class Hamburger {

	stateClasses = {
		active: 'nav--open'
	};

    isOpen = false;

	constructor($el, $backdrop) {
		this.$el = $el;
        this.$backdrop = $backdrop;
        this.$html = $('html');
		this.init();
	}

	init = () => {
		console.log('Hamburger initted', this);
		this.$el.on('click', this.clickHandler);
        this.$backdrop.on('click', this.close);
	}

	clickHandler = () => {
		console.log('Hamburger Clicked')

        if(this.isOpen) {
            this.close();
        } else {
            this.open();
        }

        
	}

    open = () => {
        this.$html.addClass(this.stateClasses.active);
        this.isOpen = true;
    }

    close = () => {
        this.$html.removeClass(this.stateClasses.active);
        this.isOpen = false;
    }


}

export default Hamburger;