class Accordion {

    stateClasses = {
        active: 'accordion--active'
    };

    constructor($el) {
        this.$el = $el;
        this.$header = $el.find('[data-accordion="header"]');
        this.$panel = $el.find('[data-accordion="panel"]');
        this.init();
    }

    init = () => {
        this.$header.on('click', this.clickHandler);
    }

    clickHandler = () => {
        const { active } = this.stateClasses;
        this.$el.toggleClass(active);

        if(this.$el.hasClass(active)) {
            this.$panel.css('max-height', this.$panel[0].scrollHeight + 'px');

        } else {
            this.$panel.css('max-height', 0);

        }
    }


}

export default Accordion;