class ShieldForm {
   constructor($el) {
    this.$el = $el;
    this.$buyerChoice = $el.find('[data-shield-form="buyerChoice"]');
    this.$company = $el.find('[data-shield-form="company"]');
    this.$companyInput = this.$company.find('input');
    this.init();
   }

   init = () => {
    this.$buyerChoice.on('change', this.handleBuyerChange);
   }

    handleBuyerChange = (event) => {
        if($(event.target).val() == "private"){
            this.$company.addClass('d-none');
            this.$companyInput.prop('required', false);
            this.$companyInput.val('');
        }
        else{
            this.$company.removeClass('d-none');
            this.$companyInput.prop('required', true);
        }
   }
}

export default ShieldForm;